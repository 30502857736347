.home-section {
    padding: 155px 0 20px 0;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
}

.headline {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.home-container {
    line-height: 36px;
}

#wrapper {
    position: relative;
    background: #fff;
}

.dark-section {
    background: #31383c;
    padding: 65px 0 80px 0;
    color: #e8e8e8;
}

.wrapper {
    padding: 80px 0 80px 0;
}

#intro .headtitle {
    text-transform: none;
    font-weight: 400;
    line-height: 48px;
    font-size: 24px;
}

#intro p {
    margin-bottom: 35px;
}

.workit-box p {
    margin-bottom: 10px;
}

.half-wrapper {
    position: relative;
    overflow: hidden;
}

.half-column {
    padding: 0;
}

.set-left,
.set-right {
    position: relative;
}

.fluid {
    background: #f7f8fa;
    z-index: 1000;
    position: absolute;
    padding: 160px 0 160px 0;
}

.set-left .fluid {
    border-right: 1px solid #e2e2e2;
    text-align: right;
}

.set-right .fluid {
    border-left: 1px solid #e2e2e2;
    text-align: left;
}

.set-left .half-col-containt {
    padding: 80px 30px 80px 15px;
}

.set-right .half-col-containt {
    padding: 80px 15px 80px 30px;
}

.imac-wrapper {
    position: relative;
    margin: 100px 0 200px 0;
}

.imac-slider {
    background: #333;
    position: absolute;
    z-index: 99;
    width: auto;
    height: auto;
    top: 49px;
    left: 116px;
}

.subscribe-form {
    display: block;
    width: 100%;
    position: relative;
}

.subscribe-form .subscribe {
    border: 5px solid #686f72;
    width: 100%;
    height: 80px;
    display: block;
    padding: 30px 25px;
    background: none;
    color: #7a848a;
    border-radius: 2px;
}

.subscribe::-moz-placeholder {
    color: #7b8387;
    opacity: 1;
}
.subscribe:-ms-input-placeholder {
    color: #7b8387;
}
.subscribe::-webkit-input-placeholder {
    color: #7b8387;
}

.btn-subscribe {
    position: absolute;
    border: none;
    width: 66px;
    height: 66px;
    border-radius: 2px;
    color: #31383c;
    text-align: center;
    top: 7px;
    right: 7px;
    font-size: 32px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

ul.listForm {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 60px 0;
}

ul.listForm li {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 30px 0;
    list-style: none;
}

ul.listForm li.first-list {
    margin-right: 2%;
}

ul.listForm li.full-list {
    width: 100%;
}

#sendmessage {
    display: none;
}

#sendmessage.show,
.show {
    display: block;
}

.heading {
    position: relative;
    color: #333333;
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-top: -10px;
}

.heading h1,
.heading h2,
.heading h3,
.heading h4,
.heading h5,
.heading h6 {
    font-weight: 400;
    margin-bottom: 0;
}

.heading p {
    margin-bottom: 0;
}

.heading span {
    position: absolute;
    width: 60px;
    height: 5px;
    display: block;
    bottom: 0;
    left: 0;
}

.heading.centered {
    text-align: center;
}

.heading.centered span {
    left: 50%;
    margin-left: -30px;
}

.parallax .heading {
    color: #fff;
}

#toTop {
    display: none;
    text-decoration: none;
    z-index: 9999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    overflow: hidden;
    width: 51px;
    height: 51px;
    border: none;
    border-radius: 2px;
    text-indent: -999px;
    background-image: url(~/public/assets/frontend/img/ui.totop.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-color: rgba(0, 0, 0, 0.4);
}

#toTopHover {
    background-image: url(~/public/assets/frontend/img/ui.totop.png);
    background-repeat: no-repeat;
    background-position: left -51px;
    width: 51px;
    height: 51px;
    display: block;
    overflow: hidden;
    float: left;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
}

#toTop:active,
#toTop:focus {
    outline: none;
}

.icon-left {
    float: left;
    margin: 5px 20px 40px 0;
}

.icon-right {
    float: left;
    margin: 5px 0 40px 20px;
}

.workit-box .icon-left,
.workit-box .icon-right {
    margin-bottom: 80px;
}

.icon-2x {
    font-size: 2.2em;
}
.icon-3x {
    font-size: 3.2em;
}
.icon-4x {
    font-size: 4.2em;
}
.icon-5x {
    font-size: 5.2em;
}

.marginbot20 {
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .fluid {
        padding: 80px 0 80px 0;
    }
    .set-left .half-col-containt {
        padding: 80px 30px 80px 0;
    }
    .set-right .half-col-containt {
        padding: 80px 0 80px 30px;
    }
    .imac-wrapper {
        margin: 140px 0 140px 0;
    }
    .imac-slider {
        width: auto;
        height: auto;
        top: 60px;
        left: 140px;
    }
    .home-container {
        margin-top: 100px;
    }
    .diamondswrap {
        text-align: center;
        padding: 0px 50px 110px 50px;
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .navbar-default .navbar-nav > li > a {
        padding: 8px 5px 8px 5px;
    }
    .navbar-default .navbar-nav > li > a.login {
        padding: 8px 10px 8px 10px;
    }
    .home-slider {
        width: 100%;
        margin-bottom: 100px;
        margin-top: 0;
    }
    .register-form {
        width: 100%;
    }
    .home-container {
        margin-top: 40px;
    }
    #flexprimary .flex-control-nav {
        text-align: center;
    }
    .fluid {
        position: relative;
        display: block;
        width: 100%;
        padding: 80px 0 80px 0;
        clear: both;
    }
    .set-left .fluid,
    .set-right .fluid {
        border: none;
    }
    .half-column {
        margin-left: -10px;
        margin-right: -10px;
        padding: 0;
    }
    .imac-wrapper {
        position: relative;
        margin: 0;
    }
    .imac-slider {
        width: auto;
        height: auto;
        top: 62px;
        left: 100%;
        margin-left: -469px;
    }
    .workit-box {
        clear: both;
    }
    .diamondswrap {
        text-align: center;
        padding: 0 0 120px 0;
    }
    .map {
        height: 450px;
    }
}
@media (max-width: 767px) {
    .navbar-default {
        background: rgba(255, 255, 255, 0.96);
        padding-top: 20px;
        padding-bottom: 20px;

        .container {
            flex-direction: row-reverse;
        }
    }
    .navbar-default:hover {
        background: rgba(255, 255, 255, 1);
    }
    .navbar-brand {
        padding: 5px 15px 5px 15px;
    }
    .navbar-brand .scroller-logo {
        top: 5px;
        left: 15px;
        display: inline-block;
    }
    .navbar-brand .default-logo {
        display: none;
    }
    .navbar-default .navbar-nav > li > a {
        color: #333;
    }
    .navbar-default .navbar-nav > li > a.login {
        border: 1px solid #333;
    }
    .home-slider {
        width: 100%;
        margin-bottom: 100px;
        margin-top: 0;
    }
    .register-form {
        width: 100%;
    }
    .form-line li {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
    }
    #flexprimary .flex-control-nav {
        text-align: center;
    }
    .home-container {
        margin-top: 40px;
    }
    .fluid {
        position: relative;
        display: block;
        width: 100%;
        padding: 80px 0 80px 0;
        clear: both;
    }
    .imac-wrapper {
        position: relative;
        margin: 0;
    }
    .imac-wrapper img.img-responsive {
        display: block;
        width: 100%;
    }
    .imac-slider {
        width: auto;
        height: auto;
        top: 10%;
        left: 24%;
    }
    .workit-box {
        clear: both;
    }
    .subfooter {
        padding: 20px;
    }
}

a,
a:hover,
.static-nav-collapse .navbar-nav > li > a:hover,
.top-nav-collapse .navbar-nav > li > a:hover,
.static-nav-collapse .navbar-nav > li > a:active,
.top-nav-collapse .navbar-nav > li > a:active,
.static-nav-collapse .navbar-nav > li > a:focus,
.top-nav-collapse .navbar-nav > li > a:focus,
.static-nav-collapse .navbar-nav li a.selected,
.static-nav-collapse .navbar-nav .active a,
.static-nav-collapse .navbar-nav .dropdown.active a,
.static-nav-collapse .navbar-nav .active a:hover,
.static-nav-collapse .navbar-nav .dropdown.active a:hover,
.static-nav-collapse .navbar-nav .active a:focus,
.static-nav-collapse .navbar-nav .dropdown.active a:focus,
.top-nav-collapse .navbar-nav li a.selected,
.top-nav-collapse .navbar-nav .active a,
.top-nav-collapse .navbar-nav .dropdown.active a,
.top-nav-collapse .navbar-nav .active a:hover,
.top-nav-collapse .navbar-nav .dropdown.active a:hover,
.top-nav-collapse .navbar-nav .active a:focus,
.top-nav-collapse .navbar-nav .dropdown.active a:focus,
.btn-primary.btn-bordered,
.start-video,
ol.breadcrumb li a:hover,
ol.breadcrumb li.active,
.heading h1,
.heading h2,
.heading h3,
.heading h4,
.heading h5,
.heading h6,
.check-list li .fa,
.icon-color,
.pricing-head span.pricing-price,
.pricing-head sup,
.pricing-containt ul li .fa.fa-check,
.validation,
ul.footer-link li a:hover,
footer ul.recent li a:hover,
ul.pagination li a:hover,
ul.cat li a:hover {
    color: #20c3c4;
}

.static-nav-collapse .navbar-nav > li > a.login:hover,
.top-nav-collapse .navbar-nav > li > a.login:hover,
.static-nav-collapse .navbar-nav > li > a.login:active,
.top-nav-collapse .navbar-nav > li > a.login:active,
.static-nav-collapse .navbar-nav > li > a.login:focus,
.top-nav-collapse .navbar-nav > li > a.login:focus,
.navbar-default .navbar-toggler:hover .icon-bar,
.btn-primary,
.default-bg,
.heading span,
.diamondswrap .item .content,
.owl-theme .owl-controls .owl-buttons div.owl-prev:hover,
.owl-theme .owl-controls .owl-buttons div.owl-next:hover,
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span,
.btn-subscribe,
.widget fieldset.search-wrapper button,
#toTopHover,
.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active,
.register-head span,
ul.tags li a:hover,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    background-color: #20c3c4;
}

.static-nav-collapse .navbar-nav > li > a:hover,
.top-nav-collapse .navbar-nav > li > a:hover,
.static-nav-collapse .navbar-nav > li > a:active,
.top-nav-collapse .navbar-nav > li > a:active,
.static-nav-collapse .navbar-nav > li > a:focus,
.top-nav-collapse .navbar-nav > li > a:focus,
.static-nav-collapse .navbar-nav li a.selected,
.static-nav-collapse .navbar-nav .active a,
.static-nav-collapse .navbar-nav .dropdown.active a,
.static-nav-collapse .navbar-nav .active a:hover,
.static-nav-collapse .navbar-nav .dropdown.active a:hover,
.static-nav-collapse .navbar-nav .active a:focus,
.static-nav-collapse .navbar-nav .dropdown.active a:focus,
.top-nav-collapse .navbar-nav li a.selected,
.top-nav-collapse .navbar-nav .active a,
.top-nav-collapse .navbar-nav .dropdown.active a,
.top-nav-collapse .navbar-nav .active a:hover,
.top-nav-collapse .navbar-nav .dropdown.active a:hover,
.top-nav-collapse .navbar-nav .active a:focus,
.top-nav-collapse .navbar-nav .dropdown.active a:focus,
.btn-primary,
.start-video,
.check-list li .fa,
.pricing-containt ul li .fa.fa-check,
.pricing-head,
.subscribe-form .subscribe:focus,
.form-control:focus,
.navbar-default .navbar-toggler:hover,
.author-testimoni .item:hover,
.author-testimoni .owl-item.synced .item,
ul.pagination li a:hover,
ul.tags li a:hover,
.tab-content {
    border-color: #20c3c4;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active,
.btn-subscribe:hover,
.widget fieldset.search-wrapper button:hover,
ul.social-link li a:hover {
    background-color: #15a4a5;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: #15a4a5;
}

.diamondswrap .item .content {
    background-color: rgba(21, 164, 165, 0.8);
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav > li > a.selected,
    .navbar-default .navbar-nav > li > a.selected:hover,
    .navbar-default .navbar-nav > li > a.selected:focus,
    .navbar-default .navbar-nav > li > a.selected:active,
    .navbar-default .navbar-nav > li > a:hover,
    .navbar-default .navbar-nav > li > a:focus,
    .navbar-default .navbar-nav > li > a:active {
        border-color: #15a4a5;
        color: #15a4a5;
    }
    .navbar-default .navbar-nav > li > a.login:hover,
    .navbar-default .navbar-nav > li > a.login:focus,
    .navbar-default .navbar-nav > li > a.login:active {
        border-color: #15a4a5;
        background-color: #15a4a5;
        color: #fff;
    }
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.basic-url {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
