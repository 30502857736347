ul.listForm {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 60px 0;
}

ul.listForm li {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 30px 0;
    list-style: none;
}

ul.listForm li.first-list {
    margin-right: 2%;
}

ul.listForm li.full-list {
    width: 100%;
}

#sendmessage {
    display: none;
}

#sendmessage.show,
.show {
    display: block;
}
