ul.social-link {
    padding: 0px;
    margin: 0px;
    display: inline-block;
}

ul.social-link li {
    display: block;
    list-style: none;
    float: left;
}

ul.social-link li:first-child a {
    margin-left: 0px;
}

ul.social-link li a {
    margin: 5px;
    background: #4b5357;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    width: 55px;
    height: 55px;
    text-align: center;
    padding-top: 13px;
    padding-left: 2px;
    font-size: 25px;
    color: #31383c;
    display: block;
}

ul.social-link li a:hover {
    color: #fff;
}
