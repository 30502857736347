.imac-wrapper {
    position: relative;
    margin: 100px 0 200px 0;
}

.imac-slider {
    background: #333;
    position: absolute;
    z-index: 99;
    width: 368px;
    height: auto;
    top: 49px;
    left: 116px;
}
