@import "~bootstrap/scss/bootstrap";

$fa-font-path: "/assets/accounts/fonts/font-awesome/";
$simple-line-font-path: "/assets/accounts/fonts/simple-line-icons/";

@import '~font-awesome/scss/font-awesome';
@import '~simple-line-icons/scss/simple-line-icons';

@import 'overwrite';
@import 'custom';
@import 'footer';
@import 'social';
@import 'contact';
@import 'features';
@import 'home';
@import 'default';

