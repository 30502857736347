a,
a:hover,
.static-nav-collapse .navbar-nav>li>a:hover,
.top-nav-collapse .navbar-nav>li>a:hover,
.static-nav-collapse .navbar-nav>li>a:active,
.top-nav-collapse .navbar-nav>li>a:active,
.static-nav-collapse .navbar-nav>li>a:focus,
.top-nav-collapse .navbar-nav>li>a:focus,
.static-nav-collapse .navbar-nav li a.selected,
.static-nav-collapse .navbar-nav .active a,
.static-nav-collapse .navbar-nav .dropdown.active a,
.static-nav-collapse .navbar-nav .active a:hover,
.static-nav-collapse .navbar-nav .dropdown.active a:hover,
.static-nav-collapse .navbar-nav .active a:focus,
.static-nav-collapse .navbar-nav .dropdown.active a:focus,
.top-nav-collapse .navbar-nav li a.selected,
.top-nav-collapse .navbar-nav .active a,
.top-nav-collapse .navbar-nav .dropdown.active a,
.top-nav-collapse .navbar-nav .active a:hover,
.top-nav-collapse .navbar-nav .dropdown.active a:hover,
.top-nav-collapse .navbar-nav .active a:focus,
.top-nav-collapse .navbar-nav .dropdown.active a:focus,
.btn-primary.btn-bordered,
.start-video,
ol.breadcrumb li a:hover,
ol.breadcrumb li.active,
.heading h1,
.heading h2,
.heading h3,
.heading h4,
.heading h5,
.heading h6,
.check-list li .fa,
.icon-color,
.pricing-head span.pricing-price,
.pricing-head sup,
.pricing-containt ul li .fa.fa-check,
.validation,
ul.footer-link li a:hover,
footer ul.recent li a:hover,
ul.pagination li a:hover,
ul.cat li a:hover {
    color: #20c3c4;
}

.static-nav-collapse .navbar-nav>li>a.login:hover,
.top-nav-collapse .navbar-nav>li>a.login:hover,
.static-nav-collapse .navbar-nav>li>a.login:active,
.top-nav-collapse .navbar-nav>li>a.login:active,
.static-nav-collapse .navbar-nav>li>a.login:focus,
.top-nav-collapse .navbar-nav>li>a.login:focus,
.navbar-default .navbar-toggler:hover .icon-bar,
.btn-primary,
.default-bg,
.heading span,
.diamondswrap .item .content,
.owl-theme .owl-controls .owl-buttons div.owl-prev:hover,
.owl-theme .owl-controls .owl-buttons div.owl-next:hover,
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span,
.btn-subscribe,
.widget fieldset.search-wrapper button,
#toTopHover,
.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active,
.register-head span,
ul.tags li a:hover,
.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus,
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    background-color: #20c3c4;
}

.static-nav-collapse .navbar-nav>li>a:hover,
.top-nav-collapse .navbar-nav>li>a:hover,
.static-nav-collapse .navbar-nav>li>a:active,
.top-nav-collapse .navbar-nav>li>a:active,
.static-nav-collapse .navbar-nav>li>a:focus,
.top-nav-collapse .navbar-nav>li>a:focus,
.static-nav-collapse .navbar-nav li a.selected,
.static-nav-collapse .navbar-nav .active a,
.static-nav-collapse .navbar-nav .dropdown.active a,
.static-nav-collapse .navbar-nav .active a:hover,
.static-nav-collapse .navbar-nav .dropdown.active a:hover,
.static-nav-collapse .navbar-nav .active a:focus,
.static-nav-collapse .navbar-nav .dropdown.active a:focus,
.top-nav-collapse .navbar-nav li a.selected,
.top-nav-collapse .navbar-nav .active a,
.top-nav-collapse .navbar-nav .dropdown.active a,
.top-nav-collapse .navbar-nav .active a:hover,
.top-nav-collapse .navbar-nav .dropdown.active a:hover,
.top-nav-collapse .navbar-nav .active a:focus,
.top-nav-collapse .navbar-nav .dropdown.active a:focus,
.btn-primary,
.start-video,
.check-list li .fa,
.pricing-containt ul li .fa.fa-check,
.pricing-head,
.subscribe-form .subscribe:focus,
.form-control:focus,
.navbar-default .navbar-toggler:hover,
.author-testimoni .item:hover,
.author-testimoni .owl-item.synced .item,
ul.pagination li a:hover,
ul.tags li a:hover,
.tab-content {
    border-color: #20c3c4;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active,
.btn-subscribe:hover,
.widget fieldset.search-wrapper button:hover,
ul.social-link li a:hover {
    background-color: #15a4a5;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: #15a4a5;
}

@media (max-width: 767px) {

    .navbar-default .navbar-nav>li>a.selected,
    .navbar-default .navbar-nav>li>a.selected:hover,
    .navbar-default .navbar-nav>li>a.selected:focus,
    .navbar-default .navbar-nav>li>a.selected:active,
    .navbar-default .navbar-nav>li>a:hover,
    .navbar-default .navbar-nav>li>a:focus,
    .navbar-default .navbar-nav>li>a:active {
        border-color: #15a4a5;
        color: #15a4a5;
    }

    .navbar-default .navbar-nav>li>a.login:hover,
    .navbar-default .navbar-nav>li>a.login:focus,
    .navbar-default .navbar-nav>li>a.login:active {
        border-color: #15a4a5;
        background-color: #15a4a5;
        color: #fff;
    }
}

.navbar-collapse.collapse.show {
    .nav-link {
        color: black;
    }

    .login {
        border: 0;
        margin-left: 0;
        padding: 8px 12px 8px 12px;
    }

    .login.router-link-active {
        border-top: 2px solid #15a4a5;
        background-color: white;
        color: #133;
    }
}

#features, #contact {
    padding-top: 95px;
    margin-top: -95px;
}
