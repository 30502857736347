footer {
    display: block;
    width: 100%;
    background: #31383c;
    color: #e8e8e8;
    padding: 80px 0 0 0;
}

footer .widget {
    margin-bottom: 30px;
}

.subfooter {
    display: block;
    width: 100%;
    background: #242a2e;
    color: #7e8183;
    padding: 20px 0 15px 0;
    margin-top: 80px;
    text-align: center;
}

.subfooter p {
    margin-bottom: 0;
}

ul.footer-link {
    padding-left: 18px;
    margin: 0px;
}

ul.footer-link li {
    list-style: url(~/public/assets/frontend/img/dotted.png);
}

ul.footer-link li a {
    color: #e8e8e8;
}

footer ul.recent {
    margin: 0;
    padding: 0;
}

footer ul.recent li {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    border-bottom: 1px solid #50575b;
}

footer ul.recent li h6 a {
    color: #e8e8e8;
}

footer ul.recent li img {
    margin-top: 8px;
}
