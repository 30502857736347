.navbar-header .navbar-brand {
    margin-left: 0 !important;
}

.navbar-header .navbar-brand:hover {
    opacity: 0.7;
}

.navbar-brand img {
    max-width: 200px;
}

.headline h1 {
    margin-bottom: 40px;
}

.headline p {
    margin-top: 20px;
}

ul.feature-list li p {
    padding-left: 50px;
}

#workit1 .col-md-4 p {
    padding-left: 50px;
}

#workit1 .row {
    margin-bottom: 30px;
}

#intro h4 a:link,
#intro h4 a:visited {
    color: #fff !important;
}

.pricing-containt {
    padding: 0 20px;
}

.pricing-containt ul {
    padding: 25px 30px 15px 30px;
}

.pricing-containt ul li {
    font-size: 17px;
}
